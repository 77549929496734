import '../css/home.css';

function Home() {
    return (

      <section className="hero-section" id="home">
          <div className='container'>
            <div className='hero-inner text-center'>
                <div className='hero-content animate__animated animate__flash'>
                   <h1><span className='bg-spaning'>Transforming</span> Industries <br/>with Innovation and Sustainability  Marketing, Technology, and More</h1>
                   <p>BRC is better way to promote your Company.</p>
                   
                </div>
                <div class="hero-btn animate__animated animate__fadeInRight">
                            <a href="#features" className='download-btn'>OUR SERVICES </a> 
                    </div>
            </div>
          </div>

      </section>
    );
}

export default Home;