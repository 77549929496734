import '../css/features.css';
// import energy from '../images/energey.png';
// import it from '../images/it.png';
// import marketing from '../images/marketing.png';
// import car from '../images/car.png';
// import gas from '../images/gas.png';
// import eco from '../images/eco.png';
import Automotive from '../images/Automotive.jpg';
import Ecostation from '../images/Ecostation.jpg';
import Energy from '../images/Energy efficiency.jpg';
import Techsoultion from '../images/Techsoultion.jpg';
import ecofabric from '../images/ecofabric.jpg';
import marketing from '../images/marketing.jpg';
function Features() {
    return (
        <section id="features" className="features bd-bottom ">
           <div className="container ">
           
              <div className="row my-4 justify-content-center gap-3 p-4">
              
                <div className="col-lg-12 col-sm-1 feature text-center">
                    <img src={Energy} alt="" srcset="" className="feature-icon"/>
                    <div className='feature-information'>
                        <p className="feature-title m-0">Energy efficiency</p>
                        <p className="feature-description m-0 d-sm-none d-lg-block">BRC specializes in optimizing energy use for businesses, helping them reduce costs and carbon footprints. Our tailored solutions focus on integrating energy-efficient technologies and practices. We aim to create sustainable business environments that contribute to a greener future
                        </p>
                    </div>
                    
                </div>
                <div className="col-lg-12 col-sm-1 feature text-center">
                    <img src={Techsoultion} alt="" srcset="" className="feature-icon"/>
                    <div className='feature-information'>
                        <p className="feature-title m-0">Technology soultion</p>
                        <p className="feature-description m-0 d-sm-none d-lg-block">BRC offers cutting-edge technology solutions to empower businesses with the tools they need to thrive in a digital world. From IT infrastructure to advanced software development, we provide customized tech services that enhance operational efficiency. Our goal is to drive innovation and streamline processes across various industries.

                        </p>
                    </div>
                    
                </div>
                <div className="col-lg-12 col-sm-1 feature text-center">
                    <img src={ecofabric} alt="" srcset="" className="feature-icon"/>
                    <div className='feature-information'>
                        <p className="feature-title m-0">Eco-fabric</p>
                        <p className="feature-description m-0 d-sm-none d-lg-block">BRC is at the forefront of sustainable textile solutions with our eco-fabric offerings. We source and supply eco-friendly fabrics that meet the highest environmental standards. Our commitment is to support businesses in adopting sustainable practices in their production lines.

                        </p>
                    </div>
                    
                </div>
                <div className="col-lg-12 col-sm-1 feature text-center">
                    <img src={marketing} alt="" srcset="" className="feature-icon"/>
                    <div className='feature-information'>
                        <p className="feature-title m-0">Marketing </p>
                        <p className="feature-description m-0 d-sm-none d-lg-block">BRC delivers comprehensive marketing solutions that elevate your brand and reach your target audience effectively. Our strategies are designed to align with your business goals, ensuring maximum impact across various platforms. We combine creativity and data-driven insights to create campaigns that resonate with your customers.

                        </p>
                    </div>
                    
                </div>
                <div className="col-lg-12 col-sm-1 feature text-center">
                    <img src={Automotive} alt="" srcset="" className="feature-icon"/>
                    <div className='feature-information'>
                        <p className="feature-title m-0">Automotive</p>
                        <p className="feature-description m-0 d-sm-none d-lg-block">BRC provides top-tier services in the automotive sector, from dealership management to innovative automotive technologies. We focus on enhancing customer experiences and optimizing operational efficiency. Our expertise ensures that your automotive business stays ahead in a competitive market.

                        </p>
                    </div>
                    
                </div>
                <div className="col-lg-12 col-sm-1 feature text-center">
                    <img src={Ecostation} alt="" srcset="" className="feature-icon"/>
                    <div className='feature-information'>
                        <p className="feature-title m-0">Eco-station</p>
                        <p className="feature-description m-0 d-sm-none d-lg-block">BRC is pioneering eco-stations that offer environmentally friendly fuel and energy options. These stations are designed to reduce environmental impact while providing reliable services to customers. Our eco-stations are a step towards a sustainable future, integrating green energy solutions into everyday life.</p>
                    </div>
                    
                </div>
                
              </div>
           </div>

        </section>
    );
}

export default Features;