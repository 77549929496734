import '../css/contact.css';
import phone from '../images/phone.png';
import location from '../images/location.png';
import email from '../images/email.png';
import facebook from '../images/facebook.png';
import youtube from '../images/youtube.png';
import instagram from '../images/instagram.png';
import tiktok from '../images/tiktok.png';
function Contact() {
    return (
        <section id="contact" className="content-section">

            <div className="container">
                <div className="row heading-social-box justify-content-evenly animate__animated animate__fadeInRight">
                    <div className="col-lg-5 col-sm-1 heading-box">
                        <h1 className='heading'>CONTACT US</h1>
                    </div>
                    <div className="col-lg-5 col-sm-1 contact-box">
                        <div className='box row'>
                            <div className='box-content'>
                                <div className='box-icon'><img src={phone} alt="" srcset="" /></div>
                                <div><p className='m-0 box-number'>966500536166</p></div>
                            </div>
                            <div className='box-content'>
                                <div className='box-icon'><img src={location} alt="" srcset="" /></div>
                                <div><p className='m-0 box-number'>Riyadh, King Abdulaziz Road</p></div>
                            </div>
                            <div className='box-content'>
                                <div className='box-icon'><img src={email} alt="" srcset="" /></div>
                                <div><a href="mailto:Admin@brc-sa.com" className='m-0 box-number'>Admin@brc-sa.com</a></div>
                            </div>
                        </div>
                        
                        {/* <div className='social-media-icons'>
                           <img src={facebook} alt="" className='social-icon'/>
                           <img src={instagram} alt="" className='social-icon'/>
                           <img src={youtube} alt="" className='social-icon'/>
                           <img src={tiktok} alt="" className='social-icon'/>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    ) ;

}

export default Contact;