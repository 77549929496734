import '../css/header.css';
import BRC from '../images/BRC.svg';
function Header() {
  return (

      <div className='row'>
      <header id="header" className="header-section">
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <img src={BRC} alt="" srcset="" className='brc-logo' />
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse mx-4" id="navbarNav" >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">
                    HOME
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#features">
                    SERVICES
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#contact">
                    CONTACT
                  </a>
                </li>
                {/* <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            اللغة
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">عربي - AR</a></li>
            <li><a class="dropdown-item" href="#">انجليزي - EN</a></li>
          </ul>
        </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      </div>
     


  );
}


export default Header;
